import React from "react";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";
import uuid4 from "uuid4";

const QuickActions = React.forwardRef((props, ref) => {
  const { serviceSectionList, disabledLink } = props;

  return (
    <Container className="qa-root" fluid={true} as="section" id="services">
      {serviceSectionList && serviceSectionList.length > 0 && (
        <Row className="justify-content-center">
          <Col xs={11} sm={10}>
            <Row>
              {serviceSectionList.map((item) => (
                <Col
                  xs={6}
                  xl={true}
                  className="d-flex flex-column align-items-center qa-container text-decoration-none"
                  as={Link}
                  to={item.cta?.url}
                  key={uuid4()}
                >
                  <GatsbyImage
                    image={
                      item.image?.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={item.image?.altText}
                    className="qa-image"
                  />
                  <p className="text-lg text-center text-black ">
                    {item.heading}
                  </p>
                  {!disabledLink && (
                    <p className="text-orange fw-bold">
                      {item.link?.title ?? "More"}▸
                    </p>
                  )}
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      )}
    </Container>
  );
});

export default QuickActions;
